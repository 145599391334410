@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Segoe UI";
  src: url("./fonts/Segoe\ UI.ttf");
}

@font-face {
  font-family: "Segoe UI Bold";
  src: url("./fonts/Segoe\ UI\ Bold.ttf");
}

/* -------------------------------------------------------- */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  /* background-color: rgb(231, 231, 231); */
  background-color: white;
}
.container {
  width: 80%;
  margin: auto;
}
.activeNav {
  background-color: #f5f5f5;
}
.activeDot {
  display: flex;
}

.circle {
  width: 100px;
  height: 100px;
  background-color: white;
  border-radius: 50%;
}

.rec.rec-carousel-wrapper .rec-carousel button {
  background: white;
  color: black;
}

.rectangle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#host_screen_scroll::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.fillGreen {
  filter: invert(55%) sepia(35%) saturate(875%) hue-rotate(68deg)
    brightness(103%) contrast(80%);
}

#selectBox_entries {
  /* border: none; */
  outline: none;
  scroll-behavior: smooth;
}
#host_screen_scroll::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.Modal {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  max-width: 1000px;
  height: 600px;
  width: 60%;
  max-height: 90vh;
  overflow-y: auto;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}



.tab-button {
  background-color: transparent;
  border: none;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  padding: 8px 16px;
  color: #333;
}

.tab-button.active {
  background-color: red;
  border-radius: 9999px ;
  padding: 10px 75px 10px 75px;
  color: white;

}